<template lang="pug">
	div
		dgbrt-loading(v-if="loading")
		template(v-else)

			template(v-if="step == 1")
				div(class="row")
					div(class="col s12")
						separator(text="Jetzt Info Tickets sichern!")

				div(class="row")
					div(class="col s12")
						div(class="card")
							div(class="card-content")
								table(class="articles-table highlight")
									tbody
										tr
											td(class="article-name-cell")
												span(class="article-name") E-Ticket Infos
												div
													small(class="article-details") Hol dir Informationen zu den E-Tickets
											td(class="article-details-cell")
												div(class="wrapper")
													div(class="article-price")
														div(class="right-align")
															span(class="price-label available") 0,00 €
														div
															span(class="tax-disclaimer") inkl. Mwst.
													div(class="article-actions")
														button(@click="selectProduct('eticket')" data-position="top" data-tooltip="In den Warenkorb" class="btn waves-light waves-effect grey darken-4 white-text tooltipped article-add-to-cart")
															i(class="material-icons") add_shopping_cart
										tr
											td(class="article-name-cell")
												span(class="article-name") Hardticket Infos
												div
													small(class="article-details") Hol dir Informationen zu den Hardtickets
											td(class="article-details-cell")
												div(class="wrapper")
													div(class="article-price")
														div(class="right-align")
															span(class="price-label available") 0,00 €
														div
															span(class="tax-disclaimer") inkl. Mwst.
													div(class="article-actions")
														button(@click="selectProduct('hardticket')" data-position="top" data-tooltip="In den Warenkorb" class="btn waves-light waves-effect grey darken-4 white-text tooltipped article-add-to-cart")
															i(class="material-icons") add_shopping_cart

			template(v-if="step == 2")
				div(class="row")
					div(class="col s12")
						separator(text="Warenkorb")

				div(class="row")
					div(class="col s12")
						div(class="card")
							div(class="card-content")
								div(class="cart-data-wrapper")
									div(class="row")
										div(class="col s12")
											table(class="cart-table highlight")
												thead
													tr(class="cart-head-row")
														td(class="bold") Artikel
														td(class="bold hide-on-small-only") Preis
														td(class="bold hide-on-small-only") Anzahl
														td(class="bold hide-on-small-only") Gesamtpreis
														td(class="bold hide-on-small-only right-align") Aktionen
												tbody
													tr(class="cart-article-row cart-ticket-row")
														td(class="article-name-cell")
															div
																span(class="article-name" v-if="mode == 'eticket'") E-Ticket Infos
																span(class="article-name" v-if="mode == 'hardticket'") Hardticket Infos
																div
																	small(class="article-details" v-if="mode == 'eticket'") Hol dir Informationen zu den E-Tickets
																	small(class="article-details" v-if="mode == 'hardticket'") Hol dir Informationen zu den Hardtickets
															div(class="hide-on-med-and-up")
																div(class="article-cart-actions-small")
																	div 0,00€ x 1 = <span style="font-weight: bold;">0,00€</span>
																	button(@click="unselectProduct" data-position="top" data-tooltip="In den Warenkorb" class="btn waves-light waves-effect grey darken-4 white-text tooltipped article-add-to-cart")
																		i(class="material-icons") remove_shopping_cart

														td(class="article-price hide-on-small-only") 0,00€
														td(class="article-amount hide-on-small-only") 1
														td(class="article-total hide-on-small-only") 0,00€
														td(class="article-actions-wrapper hide-on-small-only")
															div(class="article-actions right-align")
																button(@click="unselectProduct" data-position="top" data-tooltip="In den Warenkorb" class="btn waves-light waves-effect grey darken-4 white-text tooltipped article-add-to-cart")
																	i(class="material-icons") remove_shopping_cart

									div(class="cart-actions")
										button(@click="unselectProduct" data-position="top" data-tooltip="In den Warenkorb" class="btn waves-light waves-effect grey darken-4 white-text tooltipped article-add-to-cart") Zurück zur Produktauswahl

				div(class="row")
					div(class="col s12")
						separator(text="Deine Daten")

				div(class="row")
					div(class="col s12")
						div(class="card")
							div(class="card-content")
								div(class="user-data-wrapper")

									div(id="error-wrapper")
										div(v-if="errors.length > 0" class="row" id="error-container")
											div(class="col s12")
												div(v-for="(e, index) in errors" class="form-error") {{ e }}
									
									div(class="row")
										div(class="col s12 input-field")
											label(for="name" class="active") Dein Name
											input(id="name" name="name" type="text" placeholder="Dein Name" v-model="user.name")

									div(class="row")
										div(class="col s12 input-field")
											label(for="email" class="active") Deine E-Mail-Adresse
											input(id="email" name="email" type="email" placeholder="Deine E-Mail-Adresse" v-model="user.email")

									div(class="row")
										div(class="col s12 input-field")
											label(for="email_confirm" class="active") Deine E-Mail-Adresse (Bestätigung)
											input(id="email_confirm" name="email_confirm" type="email" placeholder="Deine E-Mail-Adresse (Bestätigung)" v-model="user.email_confirm" @paste="rejectPaste")

									div(class="row")
										div(class="col s12 input-field")
											label(for="phone" class="active") Deine Handynummer
											input(id="phone" name="phone" type="text" placeholder="Deine Handynummer" v-model="user.phone")

									div(class="row")
										div(class="col s12 input-field")
											label(for="event" class="active") Deine Veranstaltung
											input(id="event" name="event" type="text" placeholder="Deine Veranstaltung" v-model="user.event")

									div(class="row" v-if="mode == 'hardticket'")
										div(class="col s12 input-field")
											label(for="address_street" class="active") Straße + Hausnummer
											input(id="address_street" name="address_street" type="text" placeholder="Straße + Hausnummer" v-model="user.address_street")

									div(class="row" v-if="mode == 'hardticket'")
										div(class="col s12 m4 input-field")
											label(for="address_zip" class="active") PLZ
											input(id="address_zip" name="address_zip" type="text" placeholder="PLZ" v-model="user.address_zip")
										div(class="col s12 m8 input-field")
											label(for="address_city" class="active") Ort
											input(id="address_city" name="address_city" type="text" placeholder="Ort" v-model="user.address_city")

									div(class="row")
										div(class="col s12 input-field")
											label(for="note" class="active") Notiz für uns
											textarea(class="materialize-textarea" id="note" name="note" placeholder="Notiz für uns" v-model="user.note")

								div(class="total-wrapper")
									div(class="total") 0,00€
									div(class="tax") Inkl. MwSt

								div(class="submit-wrapper")
									button(@click="submitCart" class="btn z-depth-0 form-submit-button")
										i(class="material-icons") add_shopping_cart
										span Jetzt Infos anfordern!

			template(v-if="step == 3")
				div(class="row")
					div(class="col s12")
						separator(text="Danke für dein Interesse!")

				div(class="row")
					div(class="col s12")
						div(class="card")
							div(class="card-content")
								div(class="center-align" style="margin: 2rem 0;") Wir melden uns so schnell wie mögich bei dir!
								div(style="display: flex; flex-direction: row; justify-content: center")
									button(@click="reset" class="btn z-depth-0" style="font-weight: bold;")
										span Zurück zur Startseite!

</template>

<style scoped>
table {
	color: #000;
}

.card-content {
	color: #000;
}

.input-field.col label {
	text-transform: uppercase;
	color: #000;
	font-size: 1.1rem;
    left: 1.75rem;
}

table.cart-table .cart-head-row {
	font-weight: bold;
}

.form-error {
	color: red;
	text-transform: uppercase;
	font-weight: bold;
}

.grey.darken-4 {
    background-color: #212121 !important;
}

.article-name {
    font-weight: bold;
    text-transform: uppercase;
}

.article-details {
	font-style: italic;
}

.tax-disclaimer {
    font-size: 75%;
    font-style: italic;
    font-weight: normal;
}

.article-details-cell .wrapper {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	justify-content: flex-end;
}

.total-wrapper .total {
	text-align: center;
	font-size: 4rem;
	text-transform: uppercase;
	font-weight: bold;
	color: #000;
}

.total-wrapper .tax {
	font-style: italic;
	text-align: center;
	font-size: .75rem;
	color: #000;
}

.submit-wrapper {
	margin: 2rem 0 0 0;
	text-align: center;
}

.form-submit-button {
	display: flex; 
	margin: 0px auto; 
	font-weight: bold;
}

.article-cart-actions-small {
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.cart-actions {
	display: flex;
	flex-direction: row;
	align-items: center;
    justify-content: flex-start;
}

@media only screen and (max-width: 520px) {
	table.articles-table tbody tr {
		display: flex;
		flex-direction: column;
	}

	.cart-actions {
		justify-content: center;
	}
}

</style>

<script>
import Separator from '@/components/Separator';
import Loading from '@/components/Loading';
import validator from 'validator';
import axios from 'axios';

export default {
	mixins: [],
	data: function() {
		return {
			endpoint: 'https://website-backend.dagorbert-apps.de/mails/fof-promo',
			step: 1,
			mode: 'eticket',
			loading: false,
			errors: [],
			user: {
				name: '',
				email: '',
				email_confirm: '',
				phone: '',
				event: '',
				address_street: '',
				address_zip: '',
				address_city: '',
				note: '',
			},
		}
	},
	components: {
		'separator': Separator,
		'dgbrt-loading': Loading,
	},
	mounted: function() {
	},
	methods: {
		showErrors() {
			window['document']['querySelector']('#error-wrapper').scrollIntoView({
				behavior: 'smooth'
			});
		},
		rejectPaste(evt) {
			alert('Kopieren/Einfügen ist für dieses Feld deaktiviert.');
			evt.preventDefault();
			evt.stopPropagation();
			return false;
		},
		selectProduct: function(mode) {
			mode = ['eticket', 'hardticket'].indexOf(mode) > -1 ? mode : 'eticket';
			this.mode = mode;
			this.step = 2;

			let msg = 'Das '+(mode == 'hardticket' ? 'Hardticket' : 'E-Ticket')+' wurde erfolgreich in den Warenkorb gelegt!';

			window['M']['toast']({html: msg})
		},
		unselectProduct: function() {
			this.step = 1;
			window['M']['toast']({html: 'Der Warenkorb wurde geleert'})
		},
		submitCart() {

			this.errors = [];

			if(this.user.name.length < 1) {
				this.errors.push('Du musst deinen Namen eingeben');
				this.showErrors();
				return;
			}

			if(this.user.email.length < 1) {
				this.errors.push('Du musst deine E-Mail-Addresse eingeben');
				this.showErrors();
				return;
			} else if(!validator.isEmail(this.user.email)) {
				this.errors.push('Diese E-Mail Adresse ist ungültig');
				this.showErrors();
				return;
			} else if(this.user.email != this.user.email_confirm) {
				this.errors.push('Die angegebenen E-Mail Adressen stimmen nicht überein');
				this.showErrors();
				return;
			}

			if(this.user.phone.length < 1) {
				this.errors.push('Du musst deine Handynummer eingeben');
				this.showErrors();
				return;
			} else if(!validator.isMobilePhone(this.user.phone)) {
				this.errors.push('Diese Handynummer ist ungültig');
				this.showErrors();
				return;
			}

			if(this.user.event.length < 1) {
				this.errors.push('Du musst deine Veranstaltung eingeben');
				this.showErrors();
				return;
			}

			if(this.mode == 'hardticket') {
				if(this.user.address_street.length < 1) {
					this.errors.push('Du musst Straße + Hausnummer eingeben');
					this.showErrors();
					return;
				}
				if(this.user.address_zip.length < 1) {
					this.errors.push('Du musst die PLZ eingeben');
					this.showErrors();
					return;
				}
				if(this.user.address_city.length < 1) {
					this.errors.push('Du musst den Ort eingeben');
					this.showErrors();
					return;
				}
			} else {
				this.user.address = '';
			}

			let formData = {
				mode: this.mode,
				name: this.user.name,
				email: this.user.email,
				phone: this.user.phone,
				event: this.user.event,
				address_street: this.user.address_street,
				address_zip: this.user.address_zip,
				address_city: this.user.address_city,
				note: this.user.note,
			};

			this.errors = [];
			this.loading = true;
			window.scrollTo({top: 0, behavior: 'smooth'});
			var vm = this;

			axios.post(this.endpoint, formData)
				// eslint-disable-next-line no-unused-vars
				.then(function (response) {
					vm.step = 3;
					vm.loading = false;
					vm.resetUserData();
				})
				.catch(function (error) {
					vm.loading = false;
					if(error.response) {
						vm.errors.push(error.response.data);
						vm.showErrors();
					} else {
						vm.errors.push(error);
						vm.showErrors();
					}
					vm.formState = 'initial';
					vm.loading = false;
					vm.$nextTick(function() {
						window['document'].querySelector('#error-container').scrollIntoView({ block: 'start',  behavior: 'smooth' });
					});
				});
		},
		resetUserData() {
			this.mode = 'eticket';
			this.user.name = '';
			this.user.email = '';
			this.user.email_confirm = '';
			this.user.phone = '';
			this.user.event = '';
			this.user.address = '';
			this.user.note = '';
		},
		reset() {
			this.errors = [];
			this.step = 1;
			this.resetUserData();
		}
	}
}
</script>